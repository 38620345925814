import { extractFields } from '@innedit/innedit-type';
import { DataProps, DataWithChildren, WrapperProps } from 'packages/formidable';
import React, { FC, SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayPush, change } from 'redux-form';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import HOCGroup from '~/components/Group/HOC';
import IconDelete from '~/icons/Delete';
import IconEdit from '~/icons/Edit';
import IconPaste from '~/icons/Paste';
import IconPlus from '~/icons/Plus';

import Modal from './Modal';

export interface FieldProps {
  label: string;
  name: string;
  type: string;
}

export interface SchemasDatasProps extends Omit<DataProps, 'componentType'> {
  formName: string;
  name: string;
  title?: string;
}

const Wrapper: FC<WrapperProps> = function ({
  addAfterOnClick,
  addBeforeOnClick,
  addInsideOnClick,
  children,
  componentType,
  editOnClick,
  name,
  position,
  removeOnClick,
}) {
  return (
    <div className="border w-full flex flex-col rounded mb-2">
      <div className="bg-light-300 flex space-x-2 mb-2 p-2 text-sm items-center justify-between rounded-t">
        <span>
          {componentType} {name}
        </span>
        <ButtonGroup>
          <Button
            color="neutral"
            data-position={position}
            iconLeft={IconPlus}
            onClick={addBeforeOnClick}
            size="xs"
            variant="outline"
          />
          <Button
            color="neutral"
            data-position={position}
            iconLeft={IconEdit}
            onClick={editOnClick}
            size="xs"
            variant="outline"
          />
          <Button
            color="neutral"
            data-position={position}
            iconLeft={IconDelete}
            onClick={removeOnClick}
            size="xs"
            variant="outline"
          />
        </ButtonGroup>
      </div>
      <div className="p-2 flex-1">
        <div>{children}</div>
        {['box', 'grid', 'flex', 'group'].includes(componentType) && (
          <Button
            className="mt-2"
            color="neutral"
            data-position={position}
            iconRight={IconPlus}
            onClick={addInsideOnClick}
            size="xs"
            text="Ajouter un enfant"
            variant="outline"
          />
        )}
      </div>
      <div className="bg-light-300 flex space-x-2 mt-2 p-2 text-sm items-center justify-end rounded-b">
        <Button
          color="neutral"
          data-position={position}
          iconLeft={IconPlus}
          onClick={addAfterOnClick}
          size="xs"
          variant="outline"
        />
      </div>
    </div>
  );
};

const SchemasDatas: FC<SchemasDatasProps> = ({
  display,
  formName,
  name,
  params,
  title = 'Liste des datas',
}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalName, setModalName] = useState<string>();

  const values = useSelector((state: any) => state.form[formName].values);
  const datas = values[name];

  const handleAddAfterOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      const positions = p
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/datas/g, '')
        .split('.');
      if (positions.length > 0) {
        const lastIndex = parseInt(String(positions.pop()), 10);

        const newDatas = Array.isArray(datas) ? [...datas] : [{ ...datas }];
        let tmpDatas = newDatas;
        positions.forEach(position => {
          tmpDatas = tmpDatas[parseInt(position, 10)].datas;
        });

        if (lastIndex < tmpDatas.length - 1) {
          tmpDatas.splice(lastIndex + 1, 0, {
            componentType: '',
          });
        } else {
          tmpDatas.push({
            componentType: '',
          });
        }

        dispatch(change(formName, name, newDatas));
      }
    }
  };

  const handleAddBeforeOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      const positions = p
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/datas/g, '')
        .split('.');
      if (positions.length > 0) {
        const lastIndex = parseInt(String(positions.pop()), 10);

        const newDatas = Array.isArray(datas) ? [...datas] : [{ ...datas }];
        let tmpDatas = newDatas;
        positions.forEach(position => {
          tmpDatas = tmpDatas[parseInt(position, 10)].datas;
        });

        if (lastIndex > 0) {
          tmpDatas.splice(lastIndex, 0, {
            componentType: '',
          });
        } else {
          tmpDatas.unshift({
            componentType: '',
          });
        }

        dispatch(change(formName, name, newDatas));
      }
    }
  };

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    dispatch(
      arrayPush(formName, name, {
        componentType: '',
      }),
    );
  };

  const handleAddInsideOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      const positions = p
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/datas/g, '')
        .split('.');

      const newDatas = Array.isArray(datas) ? [...datas] : [{ ...datas }];
      let tmpDatas = newDatas;
      positions.forEach(position => {
        if (!tmpDatas[parseInt(position, 10)].datas) {
          tmpDatas[parseInt(position, 10)].datas = [];
        }
        tmpDatas = tmpDatas[parseInt(position, 10)].datas;
      });
      tmpDatas.push({
        componentType: '',
      });

      dispatch(change(formName, name, newDatas));
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEditOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      setModalName(p);
      setOpenModal(true);
    }
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const p = event.currentTarget.getAttribute('data-position');
    if (p) {
      const positions = p
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/datas/g, '')
        .split('.');
      if (positions.length > 0) {
        const lastIndex = parseInt(String(positions.pop()), 10);

        const newDatas = Array.isArray(datas) ? [...datas] : [{ ...datas }];
        let tmpDatas = newDatas;
        positions.forEach(position => {
          tmpDatas = tmpDatas[parseInt(position, 10)].datas;
        });

        tmpDatas.splice(lastIndex, 1);

        dispatch(change(formName, name, newDatas));
      }
    }
  };

  const handleImportOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const text = await navigator.clipboard.readText();
    const value = JSON.parse(text);
    // On ajoute les nouveaux datas
    dispatch(arrayPush(formName, name, value));

    // On recherche s'il existe des fields
    const valueFields = extractFields(Array.isArray(value) ? value : [value]);

    // On ajoute les nouveaux fields
    Object.keys(valueFields).forEach(key => {
      // newFields.push(valueFields[key]);
      dispatch(
        arrayPush(formName, 'fields', {
          ...valueFields[key],
          label: valueFields[key].label ?? key,
        }),
      );
    });
  };

  return (
    <HOCGroup
      customInfos={
        <>
          <Button
            iconLeft={IconPaste}
            onClick={handleImportOnClick}
            variant="sub-action"
          />
          <Button
            iconLeft={IconPlus}
            onClick={handleAddOnClick}
            variant="sub-action"
          />
        </>
      }
      display={display}
      title={title}
    >
      {openModal && modalName && (
        <Modal
          closeOnClick={handleCloseModal}
          formName={formName}
          name={modalName}
          params={params}
          title={modalName}
        />
      )}
      {(undefined === datas || 0 === datas.length) && (
        <div>Aucune présentation personnalisée</div>
      )}
      <DataWithChildren
        className="mt-0"
        componentType="flex"
        datas={datas as DataProps}
        formName={formName}
        mode="creation"
        params={params}
        wrapper={Wrapper}
        wrapperFunc={{
          addAfterOnClick: handleAddAfterOnClick,
          addBeforeOnClick: handleAddBeforeOnClick,
          addInsideOnClick: handleAddInsideOnClick,
          editOnClick: handleEditOnClick,
          removeOnClick: handleRemoveOnClick,
        }}
      />
    </HOCGroup>
  );
};

export default SchemasDatas;
