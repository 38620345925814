import { ChecklistType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Checklist extends ModelEspace<ChecklistType> {
  constructor(props: Omit<ModelEspaceProps<ChecklistType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'checklists',
      orderDirection: 'desc',
      orderField: 'createdAt',
      queryBy: 'label',
      ...props,
    });
  }

  public initialize(data?: Partial<ChecklistType>): Partial<ChecklistType> {
    return super.initialize({
      nbChildren: 0,
      ...data,
    });
  }
}

export default Checklist;
