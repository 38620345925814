import { css } from '@emotion/react';
import styled from '@emotion/styled';

import colors from '~/styles/colors';
import control from '~/styles/control';
import spacing from '~/styles/spacing';
import transition from '~/styles/transition';

export type ColorProp =
  | 'dark'
  | 'danger'
  | 'error'
  | 'info'
  | 'light'
  | 'link'
  | 'neutral'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'tertiary'
  | 'warning';

export const getColorStyle = (color: ColorProp): any => {
  switch (color) {
    case 'error':
      return {
        borderColor: colors.danger[500],
        color: colors.danger[500],
      };
    case 'warning':
      return {
        borderColor: colors.warning[500],
      };
    default:
      return {
        borderColor: control.border.color.base,
      };
  }
};

export const inputDisabledStyles = css`
  opacity: 0.5;
  pointer-events: none;
`;

const getStatusStyle = (status?: string) => {
  if (!status) {
    return null;
  }

  switch (status) {
    case 'error': {
      return {
        '&:hover': {
          backgroundColor: colors.danger[100],
          borderColor: colors.danger[600],
        },
        backgroundColor: colors.danger[100],
        borderColor: colors.danger[500],
        color: colors.danger[500],
      };
    }
    default:
      return null;
  }
};
const getControlStyle = ({ status }: { status?: string }): any => css`
  display: flex;
  border-color: ${control.border.color.base};
  border-style: ${control.border.type};
  border-width: ${control.border.size.base}px;
  border-radius: ${control.radius.base};
  background-color: ${control.bg.color.base};
  line-height: 2.5rem;
  padding-left: ${spacing[4]};
  padding-right: ${spacing[4]};
  transition: border-color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base},
    background-color ${transition.timing.base} ${transition.easing.base};

  &:not([type='checkbox']):not([type='radio']):focus,
  &:not([type='checkbox']):not([type='radio']):focus-within {
    outline: none;
  }

  &:focus,
  &:focus-within,
  &:hover {
    border-color: ${control.border.color.hover};
    background-color: ${control.bg.color.focus};
  }
  &:disabled {
    ${inputDisabledStyles};
    border-color: ${control.border.color.base};
  }

  ::placeholder {
    color: hsl(0, 0%, 50%);
  }

  ${getStatusStyle(status)};
`;

export const ControlSC = styled.div`
  ${getControlStyle};
  display: block;
  width: 100%;
`;

export default getControlStyle;
