// import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';

import Button from '~/components/Button';
import Input from '~/components/Input';
import { DataAttributesProps } from '~/datas/props';
import IconCheck from '~/icons/Check';

import Data from '../../packages/formidable/components/Data';

const AttributesRender: FC<
  WrappedFieldProps & Omit<DataAttributesProps, 'name'> & { openForm: boolean }
> = ({ formName, input, openForm, params }) => {
  const [text, setText] = useState<string>('');

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.currentTarget;

    setText(value);
  };

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (text) {
      let newValue = input.value;
      if (!newValue) {
        newValue = {};
      }
      newValue[text] = [''];
      input.onChange(newValue);
      setText('');
    }
  };

  return (
    <div>
      {openForm && (
        <div className="my-3 flex">
          <Input
            className="h-[34px] rounded-r-none text-[14px] leading-[28px]"
            onChange={handleOnChange}
            placeholder="Ajouter un nouvel attribut"
            value={text}
          />
          <Button
            className="mx-auto w-[34px] rounded-l-none border-l-0"
            color="neutral"
            iconLeft={IconCheck}
            onClick={handleAddOnClick}
            size="xs"
            variant="outline"
          />
        </div>
      )}

      <div className="value mt-3 flex flex-col space-y-1.5">
        {Object.keys(input.value).map(key => (
          <Data
            key={key}
            attributeType="input"
            componentType="attribute/display"
            formName={formName}
            label={key}
            name={`${input.name.split('.').reverse()[0]}.${key}`}
          />
        ))}
      </div>

      {/* <div className="value mt-6 flex flex-col"> */}
      {/*  {input.value && Object.keys(input.value).length > 0 && ( */}
      {/*    <DataSection */}
      {/*      datas={Object.keys(input.value).map(key => ({ */}
      {/*        attributeType: 'textarea', */}
      {/*        componentType: 'attribute', */}
      {/*        name: key, */}
      {/*      }))} */}
      {/*      formName={formName} */}
      {/*      name={input.name.split('.').reverse()[0]} */}
      {/*      params={params} */}
      {/*    /> */}
      {/*  )} */}
      {/* </div> */}
    </div>
  );
};

export default AttributesRender;
