import { DocumentType, FeatureType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import objectHash from 'object-hash';
import { DataProps } from 'packages/formidable';
import { FeatureData, ModelEspaceProps } from 'packages/innedit';
import React, { ReactElement, SyntheticEvent, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import Button from '~/components/Button';
import ViewModal from '~/components/View/Modal';
import { FieldProps, LibelleProps } from '~/datas/props';

import IconDelete from '../../../icons/Delete';
import IconEdit from '../../../icons/Edit';
import Field from '../../../styles/Field';
import Modal from './Modal';

const ItemTypes = {
  ITEM: 'item',
};

interface DragItem {
  index: number;
  photo: string;
  type: string;
}

const Libelle = <T extends FeatureType>({
  className,
  doc,
  field,
  index,
  onChange,
}: {
  className?: string;
  field?: LibelleProps<T>;
  index: number;
  doc: DocumentType<T>;
  onChange: (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
}): ReactElement | null => {
  if (field && field.options) {
    return (
      <Field
        className="is-expanded select"
        noBorderRight
        noMarginBottom
        noMarginRight
        noRightRadius
      >
        <select
          className={classnames(className, 'select')}
          data-field="select"
          data-index={index}
          data-name={field.name}
          defaultValue={doc[field.name] as unknown as string}
          onChange={onChange}
        >
          <option value="">-- veuillez selectionner une valeur --</option>
          {field.options?.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </Field>
    );
  }

  return (
    <Field
      className={className}
      noBorderRight
      noMarginBottom
      noMarginRight
      noRightRadius
    >
      <div>{doc[field?.name ?? 'label']}</div>
      <div className="label">
        <span>Libellé</span>
      </div>
    </Field>
  );
};

export interface ListItemProps<T extends FeatureType> {
  espaceId: string;
  collectionName: string;
  customFields?: FieldProps<T> | FieldProps<T>[];
  index: number;
  doc: DocumentType<T>;
  editPathname?: string;
  label?: LibelleProps<T>;
  modalDatas?: DataProps | DataProps[];
  modalFormName?: string;
  modalTitle?: string;
  onChange: (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
  parentCollectionName?: string;
  parentId?: string;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  showLibelle?: boolean;
  type?: 'parent' | 'sub';
}

const Item = ({
  espaceId,
  collectionName,
  customFields,
  editPathname,
  onChange,
  index,
  isDragging,
  doc,
  label,
  modalDatas,
  modalFormName,
  parentCollectionName,
  parentId,
  removeOnClick,
  modalTitle,
  showLibelle = true,
  type,
}: ListItemProps<FeatureType> & {
  isDragging: boolean;
}): ReactElement | null => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = (event?: SyntheticEvent<HTMLButtonElement>) => {
    event?.preventDefault();

    setOpenModal(false);
  };

  const handleOnSubmit = async (values: any) => {
    const props: ModelEspaceProps<FeatureType> = {
      collectionName,
      espaceId,
    };

    if (!espaceId || 'sub' === type) {
      props.parentCollectionName = parentCollectionName;
      props.parentId = parentId;
    }

    const featureData = new FeatureData(props);
    if (doc) {
      await featureData.update(doc.id, {
        ...values,
        updatedAt: dayjs().toISOString(),
      });
      handleCloseModal();
    }
  };

  const handleSubmitFormOnClick = () => {
    if (modalFormName) {
      dispatch(submit(modalFormName));
    }
  };

  return (
    <div
      className={classnames(
        {
          isDragging,
        },
        'flex',
      )}
    >
      {modalDatas && modalFormName && openModal && modalTitle && (
        <ViewModal
          buttons={{
            right: [
              {
                label: 'Fermer',
                onClick: handleCloseModal,
              },
              {
                label: 'Enregistrer et fermer',
                onClick: handleSubmitFormOnClick,
              },
            ],
          }}
          closeOnClick={handleCloseModal}
          title={modalTitle}
        >
          <Modal
            doc={doc}
            espaceId={espaceId}
            formName={modalFormName}
            modalDatas={modalDatas}
            onSubmit={handleOnSubmit}
          />
        </ViewModal>
      )}
      {showLibelle && (
        <Libelle
          className="flex-1"
          doc={doc}
          field={label}
          index={index}
          onChange={onChange}
        />
      )}
      {customFields &&
        (Array.isArray(customFields) ? customFields : [customFields]).map(
          (field, j) => (
            <Field
              key={objectHash(field)}
              className={classnames({
                'flex-1': !showLibelle,
                'min-w-36': showLibelle,
              })}
              noBorderRight
              noLeftRadius
              noMarginBottom
              noMarginLeft
              noMarginRight
              noRightRadius
            >
              <div>{doc[field.name]}</div>
              <div className="label">
                <span>{field.label}</span>
              </div>
            </Field>
          ),
        )}
      <div className="flex space-x-1 self-end">
        {doc.id && (
          <Button
            className="no-left-radius"
            color="neutral"
            size="sm"
            tooltip={doc.id}
            type="tooltip"
            variant="ghost"
          />
        )}
        {editPathname && (
          <Button
            color="secondary"
            iconLeft={IconEdit}
            size="sm"
            to={`${editPathname}${doc.id}/update/`}
            variant="ghost"
          />
        )}
        {!editPathname && modalDatas && (
          <Button
            color="secondary"
            iconLeft={IconEdit}
            onClick={handleOpenModal}
            size="sm"
            square
            variant="ghost"
          />
        )}
        <Button
          color="danger"
          data-index={index}
          iconLeft={IconDelete}
          onClick={removeOnClick}
          size="sm"
          square
          variant="groove"
        />
      </div>
    </div>
  );
};

const ItemPosition = ({
  espaceId,
  collectionName,
  editPathname,
  handleChangePosition,
  index,
  doc,
  label,
  modalDatas,
  modalFormName,
  modalTitle,
  onChange,
  parentCollectionName,
  parentId,
  removeOnClick,
  customFields,
  showLibelle,
  type,
}: ListItemProps<FeatureType> & {
  handleChangePosition: (oldIndex: number, newIndex: number) => void;
  index: number;
}): ReactElement | null => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
    drop(dragItem: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      handleChangePosition(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { doc, index, type: ItemTypes.ITEM },
    type: ItemTypes.ITEM,
  });

  drag(drop(ref));

  return (
    <div ref={ref} className={isOver ? 'isOver' : ''}>
      <Item
        collectionName={collectionName}
        customFields={customFields}
        doc={doc}
        editPathname={editPathname}
        espaceId={espaceId}
        index={index}
        isDragging={isDragging}
        label={label}
        modalDatas={modalDatas}
        modalFormName={modalFormName}
        modalTitle={modalTitle}
        onChange={onChange}
        parentCollectionName={parentCollectionName}
        parentId={parentId}
        removeOnClick={removeOnClick}
        showLibelle={showLibelle}
        type={type}
      />
    </div>
  );
};

export default ItemPosition;
