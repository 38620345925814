import styled from '@emotion/styled';
import { DocumentType, ModelType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { diff } from 'deep-object-diff';
import compact from 'lodash/compact';
import { ChannelData, ModelData } from 'packages/innedit';
import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { reset, submit } from 'redux-form';
import slug from 'slug';

import Actions, { MenuType } from '~/components/Actions';
import { ActionProps } from '~/components/Actions/props';
import Comments from '~/components/Comments';
import Header from '~/components/Header';
import Rating from '~/components/Rating';
import IconArchive from '~/icons/Archive';
import IconBoxArchivedSlash from '~/icons/BoxArchivedSlash';
import IconClose from '~/icons/Close';
import IconComment from '~/icons/Comment';
import IconCopy from '~/icons/Copy';
import IconDelete from '~/icons/Delete';
import IconExternalLinkAlt from '~/icons/ExternalLinkAlt';
import IconEye from '~/icons/Eye';
import IconEyeSlash from '~/icons/EyeSlash';
import IconInfoCircle from '~/icons/InfoCircle';
import IconLink from '~/icons/Link';
import IconStar from '~/icons/Star';
import IconUser from '~/icons/User';
import { ReducersProps } from '~/reducers';

const Textarea = styled.textarea`
  resize: none;
  outline: none;
  height: 24px;
  line-height: 24px;
`.withComponent(TextareaAutosize);

interface HeaderActionsProps<T extends ModelType> {
  archiveOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  closeOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  displayClose?: boolean;
  displayComments?: boolean;
  displayDescription?: boolean;
  displayDuplicate?: boolean;
  displayFeatured?: boolean;
  displayHidden?: boolean;
  displayView?: boolean;
  doc?: DocumentType<T>;
  formName: string;
  menu?: MenuType;
  model: ModelData<T>;
  subMenu?: (ActionProps | ActionProps[])[];
  title?: string;
}

const HeaderActions = <T extends ModelType>({
  archiveOnClick,
  closeOnClick,
  deleteOnClick,
  displayClose,
  displayComments,
  displayDescription,
  displayDuplicate,
  displayFeatured,
  displayHidden,
  displayView,
  doc,
  formName,
  menu,
  model,
  subMenu,
  title,
}: HeaderActionsProps<T>): ReactElement | null => {
  const dispatch = useDispatch();
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [pristine, { form }]: [boolean, ReducersProps<T>] = useSelector(
    (globalState: ReducersProps<T>) => [
      !globalState.form[formName]?.submitting &&
        0 ===
          Object.keys(
            diff(
              globalState.form[formName]?.initial,
              globalState.form[formName]?.values,
            ),
          ).length,
      globalState,
    ],
  );

  useEffect(() => {
    setShowComments(false);
  }, [model.collectionName, doc?.id]);

  const label: string | undefined =
    model.labelFields &&
    model.labelFields.length > 0 &&
    form[formName] &&
    form[formName].values
      ? model.labelFields
          .map(field => form[formName].values[field as keyof T])
          .join(' - ')
      : undefined;

  const handleViewOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    if (doc) {
      let pathname = '';
      if (model.espaceId) {
        const channelData = new ChannelData({
          espaceId: model.espaceId,
        });

        const documents = await channelData.find({
          wheres: {
            hidden: false,
            kind: {
              operator: 'in',
              value: ['profile', 'website'],
            },
          },
        });

        if (documents && documents.length > 0) {
          documents.forEach(frontend => {
            if (frontend.url) {
              pathname = frontend.url;
              if (pathname.substring(0, 'http'.length) !== 'http') {
                pathname = `https://${pathname}`;
              }
              pathname += '/';

              switch (model.collectionName) {
                case 'orders': {
                  pathname += `checkout?id=${doc.id}`;
                  break;
                }
                case 'produits': {
                  const tmpPathname =
                    doc.pathname ?? (doc as any).pathnames?.fr;
                  if (tmpPathname) {
                    pathname += `${slug(tmpPathname)}`;
                  }

                  break;
                }

                default:
              }

              window.open(pathname, '_blank');
            }
          });
        }
      }
    }
  };

  const handleCancelOnClick = () => {
    dispatch(reset(formName));
  };

  const handleOnClick = () => {
    dispatch(submit(formName));
  };

  const handleDuplicateOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (doc && doc.id) {
      const { id, ...data } = doc;
      const copy = await model.duplicate(id, data as any);
      if (copy.id) {
        // TODO changer l'url de la page pour afficher la copie
        // await navigate(`${itemPathnamePrefix}update/${copy.id}/`, {
        //   replace: true,
        // });
      }
    }
  };

  const handleHiddenOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (doc && doc.id) {
      await model.update(doc.id, { hidden: !doc?.hidden } as any);
    }
  };

  const handleFeaturedOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (doc && doc.id) {
      await model.update(doc.id, { isFeatured: !doc?.isFeatured } as any);
    }
  };

  const newMenu = {
    left: pristine && menu?.left ? [...menu.left] : [],
    right: pristine && menu?.right ? [...menu.right] : [],
  };

  if (displayClose && closeOnClick) {
    newMenu.left.push({
      icon: IconClose,
      label: 'Fermer',
      onClick: closeOnClick,
    });
  }

  if (pristine && doc) {
    if ((doc as any).contactId) {
      newMenu.right.push({
        icon: IconUser,
        label: 'Voir le contact',
        to: `/espaces/${doc.espaceId}/contacts/${
          (doc as any).contactId
        }/update`,
      });
    }
    if (displayView) {
      newMenu.right.push({
        icon: IconLink,
        label: 'Voir le document',
        target: '_blank',
        to: `/${model.collectionName}/${doc.id}`,
      });
      newMenu.right.push({
        icon: IconExternalLinkAlt,
        label: 'Voir le document',
        onClick: handleViewOnClick,
      });
    }

    if (displayDuplicate) {
      newMenu.right.push({
        icon: IconCopy,
        label: 'Dupliquer',
        onClick: handleDuplicateOnClick,
      });
    }

    if (displayHidden) {
      newMenu.right.push({
        icon: doc?.hidden ? IconEyeSlash : IconEye,
        label: doc?.hidden ? 'Rendre visible' : 'Cacher',
        onClick: handleHiddenOnClick,
      });
    }

    if (displayFeatured) {
      newMenu.right.push({
        icon: doc?.isFeatured ? IconStar : IconStar,
        iconBgColor: doc?.isFeatured ? 'currentColor' : 'transparent',
        iconClassName: 'h-[18px] w-[18px]',
        label: doc?.isFeatured
          ? 'Retirer la mise en vedette'
          : 'Mettre en vedette',
        onClick: handleFeaturedOnClick,
      });
    }

    if (archiveOnClick) {
      newMenu.right.push({
        icon: doc?.archived ? IconBoxArchivedSlash : IconArchive,
        label: doc?.archived ? 'Déarchiver' : 'Archiver',
        onClick: archiveOnClick,
      });
    }

    if (deleteOnClick) {
      newMenu.right.push({
        icon: IconDelete,
        label: 'Supprimer',
        onClick: deleteOnClick,
      });
    }
  }

  if (!pristine) {
    newMenu.right.push([
      {
        label: 'Annuler',
        onClick: handleCancelOnClick,
      },
      {
        label: doc?.id ? 'Enregistrer' : 'Créer',
        onClick: handleOnClick,
      },
    ]);
  }

  const handleToggleDescriptionOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    setShowDescription(d => !d);
  };

  const handleCommentOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setShowComments(true);
  };

  const hasComments =
    undefined !== doc && undefined !== doc.nbComments && doc.nbComments > 0;

  return (
    <>
      <Header
        menu={{
          left: newMenu.left,
          right: newMenu.right,
        }}
        subMenu={subMenu}
        title={title ?? label}
      />

      {doc && (
        <div className="mx-3 md:mx-0">
          <div className="flex items-center justify-between">
            <div>
              <Actions
                className="flex flex-wrap"
                items={compact([
                  displayDescription &&
                    doc.description && {
                      icon: IconInfoCircle,
                      // iconClassName: showDescription
                      //   ? 'transform -rotate-90'
                      //   : 'transform rotate-90',
                      label: showDescription
                        ? 'Cacher la description'
                        : 'Afficher la description',
                      onClick: handleToggleDescriptionOnClick,
                    },
                  displayComments &&
                    !hasComments &&
                    !showComments && {
                      icon: IconComment,
                      label: 'Ajouter un commentaire',
                      onClick: handleCommentOnClick,
                    },
                ])}
                variant="inline"
              />
            </div>
            <Rating docId={doc.id} model={model} value={doc.rating} />
          </div>

          {showDescription && doc.description && (
            <div className="mt-3 flex">
              <Textarea className="no flex-1 bg-transparent outline-none">
                {doc.description}
              </Textarea>
            </div>
          )}
          {model.espaceId && doc.id && (hasComments || showComments) && (
            <Comments
              className="mt-3"
              collectionName={model.collectionName}
              docId={doc.id}
              espaceId={model.espaceId}
            />
          )}

          <div className="mt-3 text-dark-300">
            <span>
              créé le {dayjs(doc.createdAt).format('dddd DD MMM YYYY à HH:mm')}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderActions;
