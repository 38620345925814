import { DocumentType, MediaType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import objectHash from 'object-hash';
import { MediaData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button, { ButtonProps } from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';
import IconFileUpload from '~/icons/FileUpload';
import IconPaste from '~/icons/Paste';
import IconRedo from '~/icons/Redo';
import svg from '~/images/dropzone.svg';

import Media from './Item';
import { List as ListSC } from './styles';

interface MediasProps {
  accept?: string;
  actions?: ButtonProps[];
  espaceId: string;
  display?: 'inside' | 'group' | 'content';
  formName?: string;
  onChange?: (values: string[]) => void;
  listClassName?: string;
  params?: { [key: string]: any };
  parentCollectionName: string;
  parentField: string;
  parentId: string;
  title: string;
}

const Medias: FC<MediasProps> = ({
  accept = '.pdf,audio/*,video/*,image/*',
  actions,
  espaceId,
  children,
  display,
  onChange,
  listClassName,
  parentCollectionName,
  parentField,
  parentId,
  title,
  ...props
}) => {
  const [medias, setMedias] = useState<DocumentType<MediaType>[]>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [previews, setPreviews] = useState<string[]>();

  useEffect(() => {
    let isMounted = true;
    const mediaData = new MediaData({
      espaceId,
      parentCollectionName,
      parentField,
      parentId,
    });

    mediaData.watch(
      newMedias => {
        if (isMounted) {
          setMedias(newMedias);
        }

        return isMounted;
      },
      {
        orderDirection: 'desc',
        orderField: 'datetime',
      },
    );

    return () => {
      isMounted = false;
    };
  }, [espaceId, parentCollectionName, parentField, parentId]);

  const onDrop = (files: FileList) => {
    const promises: Promise<DocumentType<MediaType>>[] = [];
    const tmpPreviews: any[] = [];
    for (let i = 0; i < files.length; i += 1) {
      const media = files.item(i);
      if (media) {
        const preview = URL.createObjectURL(media);
        tmpPreviews.push(preview);

        const mediaData = new MediaData({
          espaceId,
          parentCollectionName,
          parentField,
          parentId,
        });

        promises.push(mediaData.uploadFile(media));
      }
    }
    setPreviews(tmpPreviews);

    Promise.all(promises)
      .then(() => {
        setPreviews([]);

        if (onChange) {
          onChange(tmpPreviews);
        }

        return true;
      })
      .catch(error => {
        toast.error(error.message);

        return setPreviews([]);
      });
  };

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.currentTarget && e.currentTarget.files) {
      onDrop(e.currentTarget.files);
    }
  };

  const handleOpenBrowser = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handlePasteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const text = await navigator.clipboard.readText();

    // On prend l'URL et on appelle la fonction extractMedia
    const mediaData = new MediaData({
      espaceId,
      parentCollectionName,
      parentField,
      parentId,
    });

    try {
      await toast.promise(mediaData.extractFromUrl(text), {
        error: 'Problème lors du traitement',
        pending: 'Traitement en cours',
        success: 'Enregistrement du média réussi',
      });
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  const handleRefreshOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // On met à jour le premier média pour générer le média du document
    const mediaData = new MediaData({
      espaceId,
      parentCollectionName,
      parentField,
      parentId,
    });

    if (medias && medias.length > 0) {
      mediaData.update(medias[0].id, { updatedAt: dayjs().toISOString() });
    }
  };

  const handleRemoveItem = async (id: string) => {
    // Le média est lié à un document, il faut donc le supprimer directement
    // Cela est fait de cette manière, car chaque média peut avoir ses propres nom, description...
    const mediaData = new MediaData({
      espaceId,
      parentCollectionName,
      parentField,
      parentId,
    });

    await mediaData.delete(id);
  };

  const handleChangePosition = async (
    oldIndex: number,
    newIndex: number,
  ): Promise<void> => {
    if (medias && newIndex - oldIndex !== 1) {
      let datetime = dayjs().valueOf();
      const newDatetime = medias[newIndex].datetime;

      if (0 < newIndex) {
        const beforeDatetime = medias[newIndex - 1].datetime;
        const diff = Math.floor((beforeDatetime - newDatetime) / 2);
        datetime = newDatetime + diff;
      }
      if (datetime === medias[newIndex].datetime) {
        toast.error(
          "le nouveau datetime est le même que celui de l'ancienne position",
        );
      }

      const mediaData = new MediaData({
        espaceId,
        parentCollectionName,
        parentField,
        parentId,
      });

      const oldId = medias[oldIndex].id;
      const document = await mediaData.findById(oldId);
      if (document) {
        mediaData
          .update(document.id, {
            datetime,
            updatedAt: dayjs().toISOString(),
          } as Partial<MediaType>)
          .catch((error: Error) => {
            toast.error(error.message);
            console.error(error.message);
          });
      }
    }
  };

  return (
    <HOCGroup
      customInfos={
        <div className="">
          {actions &&
            actions.length > 0 &&
            actions.map(action => (
              <Button
                key={objectHash({ action })}
                {...action}
                variant="sub-action"
              />
            ))}
          <Button
            iconLeft={IconRedo}
            onClick={handleRefreshOnClick}
            title="Rafraichir les médais"
            variant="sub-action"
          />
          <Button
            iconLeft={IconPaste}
            onClick={handlePasteOnClick}
            title="Copier le lien d'une image"
            variant="sub-action"
          />
          <Button
            iconLeft={IconFileUpload}
            onClick={handleOpenBrowser}
            title="Télécharger un ou plusieurs médias"
            variant="sub-action"
          />
        </div>
      }
      display={display}
      title={title}
      {...props}
    >
      {previews && previews.length > 0 && (
        <span className="loading-in-progress">Chargement en cours</span>
      )}
      <input
        ref={inputRef}
        accept={accept}
        className="hidden"
        multiple
        onChange={handleOnChange}
        type="file"
      />
      {(!medias || 0 === medias.length) &&
        (!previews || 0 === previews.length) && (
          <div className="flex items-center justify-center">
            <img alt="Upload files" src={svg} width={100} />
          </div>
        )}
      {((medias && medias.length > 0) || (previews && previews.length > 0)) && (
        <div className="box-border w-full outline-none">
          <ListSC className={listClassName}>
            {medias?.map((media, index) => (
              <Media
                key={media.id}
                changePosition={handleChangePosition}
                index={index}
                media={media}
                removeItem={handleRemoveItem}
              />
            ))}
            {previews &&
              previews.length > 0 &&
              previews.map(media => (
                <li key={media}>
                  <figure className="aspect-ratio aspect-ratio--square">
                    <img
                      alt="chargement en cours"
                      className="aspect-ratio__content"
                      src={media}
                      style={{ filter: 'grayscale(1)' }}
                    />
                  </figure>
                </li>
              ))}
          </ListSC>
        </div>
      )}

      {children && <div className="mt-6">{children}</div>}
    </HOCGroup>
  );
};

export default Medias;
