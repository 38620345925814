import styled from '@emotion/styled';
import React, { FC, SyntheticEvent, useEffect, useRef } from 'react';

import { borderRadius, spacing } from '../../../styles/theme';
import Portal from '../../../utils/Portal';
import { MenuType } from '../../Actions';
import ModalContent from './Content';
import ModalFooter from './Footer';
import ModalHeader from './Header';

const BackgroundEL = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const ModalEL = styled.div<{
  'data-max-width'?: 'auto' | number | string;
  'data-min-width'?: 'auto' | number | string;
  'data-width'?: 'auto' | number | string;
}>`
  border-radius: ${borderRadius.s};

  background: #fff !important;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;

  margin: ${spacing[6]} 0 !important;
  max-height: calc(100vh - ${spacing[12]});
  pointer-events: auto;
  position: relative;
  min-width: ${props => props['data-min-width'] || 'auto'};
  max-width: ${props => props['data-max-width'] || '62rem'};
  width: ${props => props['data-width'] || '100%'};
`;

export interface ViewModalProps {
  buttons?: MenuType;
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  maxWidth?: 'auto' | string;
  minWidth?: 'auto' | string;
  title: string;
  width?: 'auto' | string;
}

const ViewModal: FC<ViewModalProps> = ({
  buttons,
  children,
  closeOnClick,
  maxWidth,
  minWidth,
  title,
  width,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && ref.current === event.target) {
      closeOnClick(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Portal>
      <BackgroundEL />

      <div
        ref={ref}
        className="fixed bottom-0 top-0 z-[120] flex w-full items-center justify-center"
      >
        <ModalEL
          data-max-width={maxWidth}
          data-min-width={minWidth}
          data-width={width}
          id="modal--container"
        >
          <ModalHeader closeOnClick={closeOnClick} title={title} />
          <ModalContent>{children}</ModalContent>
          <ModalFooter buttons={buttons} />
        </ModalEL>
      </div>
    </Portal>
  );
};

export default ViewModal;
