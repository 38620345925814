import React, { FC, ReactNode } from 'react';

import IconBack from '~/icons/Back';
import IconClose from '~/icons/Close';
import IconDelete from '~/icons/Delete';
import IconNext from '~/icons/Next';
import IconPlus from '~/icons/Plus';

const Icon: FC<{
  bgColor?: string;
  className?: string;
  color?: string;
  label?: string;
  size?: number;
  value: ReactNode;
}> = ({ bgColor, className, color, label, size, value }) => {
  if (typeof React.Component === typeof value) {
    const IconCmp: any = value as React.ReactElement;

    return (
      <IconCmp
        bgColor={bgColor}
        className={className}
        color={color}
        label={label}
        size={size}
      />
    );
  }

  switch (value) {
    case 'add': {
      return (
        <IconPlus
          bgColor={bgColor}
          className={className}
          color={color}
          size={size}
        />
      );
    }

    case 'close':
      return (
        <IconClose
          bgColor={bgColor}
          className={className}
          color={color}
          size={size}
        />
      );

    case 'back': {
      return (
        <IconBack
          bgColor={bgColor}
          className={className}
          color={color}
          size={size}
        />
      );
    }

    case 'next': {
      return (
        <IconNext
          bgColor={bgColor}
          className={className}
          color={color}
          size={size}
        />
      );
    }

    case 'remove': {
      return (
        <IconDelete
          bgColor={bgColor}
          className={className}
          color={color}
          size={size}
        />
      );
    }

    default:
  }

  return <>{value}</>;
};
export default Icon;
