import classnames from 'classnames';
import { navigate } from 'gatsby';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent } from 'react';

import Button from '~/components/Button';
import pathname from '~/utils/pathname';

import Checkbox from './Render/Checkbox';
import Input from './Render/Input';
import Radio from './Render/Radio';

export interface FilterActionProps {
  displayClear?: boolean;
  index: number;
  label: string;
  multiple?: boolean;
  name: string;
  options?: { label: string; value: string | number | boolean }[];
  value?: any;
}

const FilterAction: FC<FilterActionProps> = ({
  displayClear = true,
  index,
  label,
  multiple = false,
  name,
  options,
  value: defaultValue,
}) => {
  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = event.currentTarget;
    let param: string | string[] = defaultValue || [];
    if (!Array.isArray(param)) {
      param = [param];
    }
    if (multiple) {
      if (checked) {
        param.push(value);
      } else {
        const i = param.findIndex(p => p === String(value));
        if (i >= 0) {
          param.splice(i, 1);
        }
      }
    } else {
      param = value;
    }

    return navigate(
      pathname({ actions: [{ name, value: param as undefined | string }] }),
      {
        replace: true,
      },
    );
  };

  const handleOnReset = async () => {
    if (name) {
      await navigate(pathname({ actions: [{ name }] }), {
        replace: true,
      });
    }
  };

  return (
    <div
      className={classnames('py-3 px-6 text-light-600', {
        'pt-3 border-t': index > 0,
      })}
    >
      {label}
      {options && options.length > 0 ? (
        <ul>
          {options.map(option => (
            <li key={objectHash(option)}>
              {multiple ? (
                <Checkbox
                  checked={
                    undefined !== defaultValue &&
                    (Array.isArray(defaultValue)
                      ? defaultValue
                      : [defaultValue]
                    ).includes(String(option.value))
                  }
                  label={option.label}
                  name={name}
                  onChange={handleOnChange}
                  value={String(option.value)}
                />
              ) : (
                <Radio
                  checked={
                    undefined !== defaultValue &&
                    (Array.isArray(defaultValue)
                      ? defaultValue
                      : [defaultValue]
                    ).includes(String(option.value))
                  }
                  label={option.label}
                  name={name}
                  onChange={handleOnChange}
                  value={String(option.value)}
                />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <Input name={name} />
      )}
      {displayClear && (
        <Button
          className="mt-3 text-light-600 focus:text-light-300"
          disabled={!defaultValue}
          onClick={handleOnReset}
          variant="clear"
        >
          Effacer
        </Button>
      )}
    </div>
  );
};

export default FilterAction;
