import { Link } from 'gatsby';
import React, { FC, useEffect, useRef } from 'react';
import useComposedRef from 'use-composed-ref';

import { checkIfIsVisible } from '~/utils/functions';

import Icon from '../Icon';
import { ButtonProps } from './props';
import ButtonEL from './styles';

const ButtonIsVisible: FC<ButtonProps> = ({
  children,
  clickOnVisible = false,
  datas,
  iconBgColor,
  iconClassName,
  iconColor,
  iconLeft,
  iconRight,
  iconSize,
  innerRef,
  isInfinite = false,
  square,
  text,
  to,
  type = 'button',
  ...others
}) => {
  const nodeRef = useRef<HTMLButtonElement>(null);
  const composeRefs = useComposedRef(nodeRef, innerRef);

  useEffect(() => {
    const isVisible = () => {
      if (
        !nodeRef ||
        !nodeRef.current ||
        !nodeRef.current.getBoundingClientRect
      ) {
        return;
      }

      const html = document.documentElement;
      const boundingClientRect = nodeRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth || html.clientWidth;
      const windowHeight = window.innerHeight || html.clientHeight;

      if (checkIfIsVisible(boundingClientRect, windowWidth, windowHeight)) {
        if (!isInfinite) {
          removeListener();
        }

        if (clickOnVisible) {
          nodeRef.current.click();
        }
      }
    };

    const attachListener = () => {
      window.addEventListener('scroll', isVisible);
      window.addEventListener('resize', isVisible);
    };

    const removeListener = () => {
      window.removeEventListener('scroll', isVisible);
      window.removeEventListener('resize', isVisible);
    };

    if (clickOnVisible) {
      attachListener();
      isVisible();
    }

    return () => {
      removeListener();
    };
  }, [clickOnVisible, isInfinite, nodeRef]);

  const props: { [key: string]: any } = {};
  if (to) {
    const domain = (url: string) =>
      url.replace('http://', '').replace('https://', '');

    if (domain(to) === to && others.target !== '_blank') {
      props.as = Link;
      props.to = to;
    } else {
      props.as = 'a';
      props.href = to;
    }

    props.square = square?.toString();
  }

  return (
    <ButtonEL
      ref={composeRefs}
      square={square || false}
      {...others}
      {...datas}
      {...props}
    >
      {iconLeft && (
        <Icon
          bgColor={iconBgColor}
          className={iconClassName}
          color={iconColor}
          size={iconSize}
          value={iconLeft}
        />
      )}
      {children}

      {text && <div>{text}</div>}
      {iconRight && (
        <Icon
          bgColor={iconBgColor}
          className={iconClassName}
          color={iconColor}
          size={iconSize}
          value={iconRight}
        />
      )}
    </ButtonEL>
  );
};

export default ButtonIsVisible;
