import { NoteType } from '@innedit/innedit-type';
import { doc, increment, updateDoc } from 'firebase/firestore';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Note extends ModelEspace<NoteType> {
  constructor(props: Omit<ModelEspaceProps<NoteType>, 'collectionName'>) {
    super({
      ...props,
      canDoSearch: true,
      collectionName: 'notes',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'createdAt',
      queryBy: 'label, description, urls',
      tabs: [
        {
          itemMode: 'grid',
          label: 'Toutes les notes',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/notes`,
          wheres: {
            archived: false,
            parent: '',
          },
        },
        {
          label: 'En vedette',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/notes/featured`,
          wheres: {
            archived: false,
            isFeatured: true,
          },
        },
        {
          label: 'Archivées',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/notes/archived`,
          wheres: {
            archived: true,
          },
        },
      ],
    });
  }

  public initialize(data?: Partial<NoteType>): Partial<NoteType> {
    return super.initialize({
      ...data,
      nbChildren: 0,
      nbViews: data?.nbViews ?? 0,
    });
  }

  public async incrementView(docId: string): Promise<void> {
    return updateDoc(doc(this.getFirestore(), this.collectionName, docId), {
      nbViews: increment(1),
    });
  }
}

export default Note;
