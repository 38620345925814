import styled from '@emotion/styled';
import { createStyles } from 'packages/formidable/core/functions';
import FormidableContext from 'packages/formidable/FormidableContext';
import React, { FC, PropsWithChildren, useContext } from 'react';

import HTMLElementProps from '../../props';

export type GridProps = HTMLElementProps;

const GridSC = styled.div<GridProps>`
  display: grid;
  gap: ${(props: any) =>
    props &&
    !props.gap &&
    !props.g &&
    !props.rowGap &&
    !props.gX &&
    !props.columnGap &&
    !props.gY &&
    props.theme &&
    props.theme.spacing &&
    props.theme.defaultSpace &&
    props.theme.spacing[props.theme.defaultSpace]};

  & + & {
    margin-top: ${(props: any) =>
      props && props.theme && props.theme.spacing && props.theme.defaultSpace
        ? props.theme.spacing[props.theme.defaultSpace]
        : '0'};
  }

  ${createStyles};
`;

const Grid: FC<PropsWithChildren<GridProps>> = ({
  children,
  className,
  ...props
}) => {
  const { sc } = useContext(FormidableContext);

  return (
    <GridSC
      as={sc && sc.grid}
      {...props}
      className={`${className ? `${className} ` : ''}grid`}
    >
      {children}
    </GridSC>
  );
};
export default Grid;
