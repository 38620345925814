import classnames from 'classnames';
import { navigate } from 'gatsby';
import React, { FC, ReactNode, SyntheticEvent } from 'react';

import Icon from '~/components/Icon';

const MessageAction: FC<{
  icon?: ReactNode;
  label: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  size?: 'sm' | 'md';
  to?: string;
}> = ({ icon, label, onClick, size = 'md', to }) => {
  const handleOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
    if (to) {
      navigate(to);
    }
  };

  return (
    <button
      className={classnames(
        'flex cursor-pointer items-center space-x-1.5 rounded-[18px]',
        'border border-[#007AFF] text-[#007AFF] transition-all duration-300  hover:bg-[#007AFF] hover:text-light-50',
        {
          'px-3 py-1 text-[15px] leading-[18px] md:text-[14px]': 'sm' === size,
          'px-3 py-1.5 text-[17px] leading-[22px] md:text-[15px]':
            'md' === size,
        },
      )}
      onClick={handleOnClick}
      type="button"
    >
      <span>{label}</span>
      {icon && <Icon className="h-[16px] w-[16px]" value={icon} />}
    </button>
  );
};

export default MessageAction;
