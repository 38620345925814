import { FeatureType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

dayjs.extend(advancedFormat);

class Feature extends ModelEspace<FeatureType> {
  constructor(props: Omit<ModelEspaceProps<FeatureType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'features',
      ...props,
    });
  }
}

export default Feature;
