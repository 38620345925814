import { TrackingType } from '@innedit/innedit-type';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Tracking extends ModelEspace<TrackingType> {
  constructor(props: Omit<ModelEspaceProps<TrackingType>, 'collectionName'>) {
    super({
      canDoSearch: false,
      collectionName: 'trackings',
      labelFields: ['start'],
      orderDirection: 'desc',
      orderField: 'createdAt',
      queryBy: '',
      ...props,
    });
  }

  public initialize(data?: Partial<TrackingType>): Partial<TrackingType> {
    return super.initialize({
      nbChildren: 0,
      ...data,
    });
  }
}

export default Tracking;
