import React, { FC } from 'react';

import MediaVideo from '~/components/Media/Video';

interface MediaOptions {
  background?: string;
  crop?: string;
  height?: number;
  width?: number;
}
interface MediaProps {
  className?: string;
  media: any; // TODO remettre MediaNode ou le nouveau type
  options?: MediaOptions;
  thumbnail?: boolean;
}

const Media: FC<MediaProps> = ({ className, media, options, thumbnail }) => {
  const {
    background = '#ffffff',
    crop = 'max',
    height = 640,
    width = 640,
  } = options ?? {};

  if (!media) {
    return null;
  }

  // TODO tester si c'est un mediaType ou un mediaNode pour gérer les différents affichages
  let src = `${media.origin}/${media.pathname}`;

  const [type] = media.type.split('/');

  switch (type) {
    case 'application': {
      if ('application/pdf' === media.type) {
        return (
          <iframe height="100%" src={src} title={media.label} width="100%" />
        );
      }

      return <div>No pris en charge</div>;
    }

    case 'audio': {
      // eslint-disable-next-line jsx-a11y/media-has-caption
      return <audio controls src={src} />;
    }

    case 'image': {
      // if (!media.width || !media.height) {
      //   return <IconLoading />;
      // }
      if (!['image/svg+xml'].includes(media.type)) {
        src += `?w=${width}&h=${height}&c=${crop}&b=${background.slice(
          1,
        )}&fm=webp`;
      }

      return <img alt={media.label} className={className} src={src} />;
    }

    case 'video': {
      //   src += `?w=${width}&h=${height}&c=${crop}&b=${background.slice(
      //     1,
      //   )}&fm=webp`;

      // return <img alt={media.label} className={className} src={src} />;

      // return (
      //   <video
      //     className={className}
      //     controls={!thumbnail}
      //     crossOrigin="anonymous"
      //     muted
      //     src={src}
      //   />
      // );

      return (
        <MediaVideo
          className={className}
          media={media}
          options={options}
          thumbnail={thumbnail}
        />
      );
    }

    default:
      return <div>No pris en charge</div>;
  }
};

export default Media;
