module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#F8F9FA","description":"espace de travail collaboratif","display":"standalone","icons":[{"sizes":"48x48","src":"icons/icon-48-tourdepiste.png","type":"image/png"},{"sizes":"72x72","src":"icons/icon-72-tourdepiste.png","type":"image/png"},{"sizes":"92x92","src":"icons/icon-92-tourdepiste.png","type":"image/png"},{"sizes":"144x144","src":"icons/icon-144-tourdepiste.png","type":"image/png"},{"sizes":"192x192","src":"icons/icon-192-tourdepiste.png","type":"image/png"},{"sizes":"256x256","src":"icons/icon-256-tourdepiste.png","type":"image/png"},{"sizes":"384x384","src":"icons/icon-384-tourdepiste.png","type":"image/png"},{"sizes":"512x512","src":"icons/icon-512-tourdepiste.png","type":"image/png"},{"sizes":"1024x1024","src":"icons/icon-1024-tourdepiste.png","type":"image/png"}],"lang":"fr","name":"Tourdepiste","short_name":"Tourdepiste","start_url":"/","theme_color":"#F8F9FA","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/Users/mqueval/Innedit/development/innedit-cms/src/notification-sw.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/mqueval/Innedit/development/innedit-cms","commonmark":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
