import { TaskType } from '@innedit/innedit-type';
import { doc, increment, updateDoc } from 'firebase/firestore';

import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

class Task extends ModelEspace<TaskType> {
  constructor(props: Omit<ModelEspaceProps<TaskType>, 'collectionName'>) {
    super({
      canDoSearch: true,
      collectionName: 'tasks',
      orderDirection: 'desc',
      orderField: 'createdAt',
      queryBy: 'label, favorableOutcome, description',
      tabs: [
        {
          itemMode: 'grid',
          label: 'Toutes les tâches',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/tasks`,
          wheres: {
            archived: false,
            parent: '',
          },
        },
        {
          label: 'En vedette',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/tasks/featured`,
          wheres: {
            archived: false,
            isFeatured: true,
          },
        },
        {
          label: 'Archivées',
          orderDirection: 'desc',
          orderField: 'createdAt',
          pathname: `/espaces/${props.espaceId}/tasks/archived`,
          wheres: {
            archived: true,
          },
        },
      ],
      ...props,
    });
  }

  public async incrementView(docId: string): Promise<void> {
    return updateDoc(doc(this.getFirestore(), this.collectionName, docId), {
      nbViews: increment(1),
    });
  }

  public initialize(data?: Partial<TaskType>): Partial<TaskType> {
    return super.initialize({
      ...data,
      incidence: data?.incidence ?? 'normal',
      isFeasible: data?.isFeasible ?? false,
      isFeatured: data?.isFeatured ?? false,
      nbChildren: 0,
      nbViews: data?.nbViews ?? 0,
      priority: data?.priority ?? 'normal',
    });
  }
}

export default Task;
