import styled from '@emotion/styled';
import { createStyles } from 'packages/formidable/core/functions';
import FormidableContext from 'packages/formidable/FormidableContext';
import React, { FC, PropsWithChildren, useContext } from 'react';

import HTMLElementProps from '../../props';

export type FlexProps = HTMLElementProps;

const FlexSC = styled.div<FlexProps>`
  display: flex;
  justify-content: ${props =>
    !props.justify && !props.justifyContent && 'flex-start'};
  align-items: ${props => !props.items && !props.alignItems && 'stretch'};
  align-content: ${props =>
    !props.content && !props.alignContent && 'flex-start'};

  & + & {
    margin-top: ${(props: any) =>
      props && props.theme && props.theme.spacing && props.theme.defaultSpace
        ? props.theme.spacing[props.theme.defaultSpace]
        : '0'};
  }

  ${createStyles};
`;

const Flex: FC<PropsWithChildren<FlexProps>> = ({
  children,
  className,
  direction = 'column',
  ...props
}) => {
  const { sc } = useContext(FormidableContext);

  return (
    <FlexSC
      as={sc && sc.flex}
      className={`${className ? `${className} ` : ''}flex`}
      direction={direction}
      {...props}
    >
      {children}
    </FlexSC>
  );
};

export default Flex;
