import { DataType, initializeValues } from '@innedit/innedit-type';
import hash from 'object-hash';
import {
  DataFieldArrayProps,
  DataFieldProps,
  DataWithChildrenProps,
} from 'packages/formidable/components/Data/props';
import React, { FC, SyntheticEvent } from 'react';
import { FormSection, WrappedFieldArrayProps } from 'redux-form';

import Button from '~/components/Button';
import HOCGroup from '~/components/Group/HOC';

import Field from '../Field';
import Data from '../index';

const DataArrayRender: FC<
  WrappedFieldArrayProps & Omit<DataFieldArrayProps, 'name'>
> = ({
  customInfos,
  customInfosProps,
  datas,
  display,
  fieldProps,
  fields,
  formName,
  formValues,
  label,
  params,
  placeholder,
}) => {
  const newDatas = datas && !Array.isArray(datas) ? [datas] : datas;

  const handleAddButtonOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    const initialDatas = initializeValues(newDatas);
    fields.push(Object.keys(initialDatas).length > 0 ? initialDatas : '');
  };

  const handleRemoveButtonOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
    const index = event.currentTarget.getAttribute('data-index');

    if (index) {
      fields.remove(parseInt(index, 10));
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAddButtonOnClick}
      display={display}
      label={label}
    >
      {fields && (
        <div className="flex flex-col space-y-1.5">
          {fields.map((field, index) => {
            const removeCmp = (
              <Button
                className="h-[42px] w-[42px] min-w-[42px] rounded-l-none"
                color="neutral"
                data-index={index}
                iconLeft="remove"
                onClick={handleRemoveButtonOnClick}
                size="xs"
              />
            );

            if (newDatas && newDatas.length > 0) {
              if (
                newDatas.length > 1 ||
                (newDatas[0] as DataWithChildrenProps).datas ||
                (newDatas[0] as DataFieldProps).name
              ) {
                return (
                  <FormSection
                    key={`${field}_${hash({
                      ...newDatas,
                      datas: null,
                      params: null,
                    })}`}
                    className="flex flex-1"
                    data-index={index}
                    name={field}
                  >
                    {(newDatas as DataType[]).map((value, idx) => (
                      <Data
                        key={`${field}_${hash(value)}`}
                        className="flex-1"
                        {...value}
                        customInfosProps={customInfosProps}
                        fieldProps={{
                          className:
                            0 === idx ? 'rounded-r-none' : 'rounded-none',
                        }}
                        formName={formName}
                        formValues={formValues}
                        labelShow={false}
                        params={{
                          ...params,
                          index,
                          count: index + 1,
                          name: field,
                          sectionName: field,
                        }}
                      />
                    ))}
                    {customInfos}
                    {removeCmp}
                  </FormSection>
                );
              }

              return (
                <div key={field} className="flex flex-1" data-index={index}>
                  <Data
                    className="flex-1"
                    fieldProps={{
                      className: 'rounded-r-none',
                    }}
                    templateProps={{
                      className: 'flex-1',
                    }}
                    wrapperProps={{
                      className: 'flex flex-row',
                    }}
                    {...newDatas[0]}
                    customBottom={removeCmp}
                    formName={formName}
                    formValues={formValues}
                    labelShow={false}
                    name={field}
                    params={{
                      ...params,
                      name: field,
                    }}
                  />
                  {customInfos}
                </div>
              );
            }

            return (
              <div key={field} className="flex flex-1" data-index={index}>
                <Field
                  className="flex-1"
                  componentType="input"
                  customBottom={removeCmp}
                  fieldProps={{
                    className: 'rounded-r-none',
                  }}
                  formName={formName}
                  formValues={formValues}
                  labelShow={false}
                  name={field}
                  params={{
                    ...params,
                    name: field,
                  }}
                  placeholder={placeholder ?? label ?? field}
                  templateProps={{
                    className: 'flex-1',
                  }}
                  wrapperProps={{
                    className: 'flex flex-row',
                  }}
                  {...fieldProps}
                />
                {customInfos}
              </div>
            );
          })}
        </div>
      )}
    </HOCGroup>
  );
};

export default DataArrayRender;
